import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store/store";

Vue.use(VueRouter);

const router = new VueRouter({
  routes: [
    {
      path: "/",
      component: () => import("@/views/LandingPage"),
    },
    {
      path: "/",
      component: () => import("@/views/Dashboard"),
      meta: {
        requiresAuth: true,
      },
      children: [
        // SIDEBAR TABS
        {
          path: "chethans-dashboard",
          component: () => import("@/views/ChethansDashboard"),
        },
        {
          path: "/trade-log",
          component: () => import("@/views/ChethansDashboard/TradeLog"),
        },
        {
          path: "home",
          component: () => import("@/views/Dashboard/Home"),
        },
        {
          path: "live",
          component: () => import("@/views/Dashboard/LiveTrade"),
        },
        {
          path: "paper",
          component: () => import("@/views/Dashboard/PaperTrade"),
        },
        {
          path: "journal",
          component: () => import("@/views/Dashboard/TradeJournal"),
        },
        {
          path: "library",
          component: () => import("@/views/Dashboard/SystemLibrary"),
        },
        {
          path: "library/:id",
          component: () =>
            import("@/views/Dashboard/SystemLibrary/components/IntegrationDoc"),
        },
        // HEADER NAV TABS
        {
          path: "notifications",
          component: () => import("@/views/Dashboard/Settings/Notifications"),
        },
        {
          path: "settings/user",
          component: () => import("@/views/Dashboard/Settings/User"),
        },
        {
          path: "settings/broker",
          component: () => import("@/views/Dashboard/Settings/Broker"),
        },
        {
          path: "billing",
          component: () => import("@/views/Dashboard/Settings/Billing"),
        },
        {
          path: "faq",
          component: () => import("@/views/Dashboard/Settings/FAQ"),
        },
      ],
    },
    {
      path: "/login",
      component: () => import("@/views/Auth/Login"),
    },
    {
      path: "/signup",
      component: () => import("@/views/Auth/Register"),
    },
    // {
    //   path: "/chethans-dashboard",
    //   component: () => import("@/views/ChethansDashboard"),
    //   meta: {
    //     requiresAuth: true,
    //   },
    // },
    // {
    //   path: "/trade-log",
    //   component: () => import("@/views/ChethansDashboard/TradeLog"),
    //   meta: {
    //     requiresAuth: true,
    //   },
    // },
  ],
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    };
  },
  mode: "history",
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // If token exists, validate it and get user details before entering route
    if (store.getters.isLoggedIn) {
      const token = store.getters.token;
      Vue.prototype.$http.defaults.headers.common[
        "Authorization"
      ] = `JWT ${token}`;
      store.dispatch("getUserDetails", token).then(() => {
        Vue.prototype.$http.interceptors.response.use(
          (response) => {
            next();
            return response;
          },
          (error) => {
            if (error.response.status === 401) {
              store.dispatch("logout").then(() => router.push({ name: 'login', query: { redirect: '/path' } }));
            }
            return Promise.reject(error);
          }
        );
      });
      return;
    }
    next("/login");
  } else {
    next();
  }
});
export default router;
