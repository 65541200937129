<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import "@/assets/scss/light.scss";
import "bootstrap/dist/js/bootstrap";

export default {
  name: "App",
};
</script>
