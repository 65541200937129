import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import router from "../router/routes";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: localStorage.getItem("token") || "",
    user: {
      id: "",
      name: "",
      email: "",
      avatar_url: "",
    },
    brokers: [],
    allBrokers: {},
    loginStatusMap: {},
    AUM: {},
    portfolio: {},
  },
  mutations: {
    logout(state) {
      state.token = "";
      localStorage.clear();
    },
    saveBrokerData(state, brokerData) {
      state.brokers = brokerData;
    },
    saveBrokerUsers(state, brokerData) {
      state.allBrokers = brokerData;
    },
    savePortfolioAndAum(state, portfolioAndAum) {
      state.AUM = portfolioAndAum.AUM;
      state.portfolio = portfolioAndAum.balances;
    },
    saveLoginStatusMap(state, loginStatusMap) {
      state.loginStatusMap = loginStatusMap;
    },
    avatar_change(state, avatar) {
      state.user.avatar_url = avatar;
    },
    register(state, user) {
      axios({
        url: `${process.env.VUE_APP_BASE_URL}/user/`,
        data: user,
        method: "POST",
        headers: {
          Authorization: null,
        },
      }).then(
        (resp) => {
          let avatar = "";
          if (resp.data.avatar != null) {
            avatar += `${process.env.VUE_APP_BASE_URL}${resp.data.avatar}`;
          } else {
            avatar +=
              "https://lh5.googleusercontent.com/proxy/NVYx2yaGQgc0DfTX3MTF3ZrDdbLyu0nCgueTEtArs4yfC4Np0AlGtOqzr7k2j8HQrEgwj0FozOtUqvCF_7d4u4fNkY7ZRbepFQQQyr5oxQNFkP78NK8";
          }
          state.user = {
            id: resp.data.id,
            name: resp.data.first_name,
            email: resp.data.email,
            avatar_url: avatar,
          };
          axios({
            method: "get",
            url: `${process.env.VUE_APP_BASE_URL}/broker/${resp.data.id}/`,
          }).then((resp) => {
            state.brokers = resp.data;
          });
        },
        (err) => {
          const errMap = err.response.data;
          for (let key in errMap) {
            if (Object.prototype.hasOwnProperty.call(errMap, key)) {
              Vue.toasted.show(`${key}: ${errMap[key]}`);
            }
          }
        }
      );
    },
    getUserDetails(state, token) {
      localStorage.setItem("token", token);
      axios.defaults.headers.common["Authorization"] = `JWT ${token}`;
      state.token = token;
      axios({
        method: "get",
        url: `${process.env.VUE_APP_BASE_URL}/auth/users/me/`,
      }).then((resp) => {
        axios({
          method: "get",
          url: `${process.env.VUE_APP_BASE_URL}/user/${resp.data.id}/`,
        })
          .then((resp) => {
            let avatar = "";
            if (resp.data.avatar != null) {
              avatar += `${process.env.VUE_APP_BASE_URL}${resp.data.avatar}`;
            } else {
              avatar +=
                "https://lh5.googleusercontent.com/proxy/NVYx2yaGQgc0DfTX3MTF3ZrDdbLyu0nCgueTEtArs4yfC4Np0AlGtOqzr7k2j8HQrEgwj0FozOtUqvCF_7d4u4fNkY7ZRbepFQQQyr5oxQNFkP78NK8";
            }
            state.user = {
              id: resp.data.id,
              name: resp.data.first_name,
              email: resp.data.email,
              avatar_url: avatar,
            };
            axios({
              method: "get",
              url: `${process.env.VUE_APP_BASE_URL}/broker/${resp.data.id}/`,
            }).then((resp) => {
              state.brokers = resp.data;
            });
          })
          .catch(() => {
            Vue.toasted.show("Whoops! Something went wrong.");
            localStorage.removeItem("token");
          });
      });
    },
  },
  actions: {
    updateAvatar({ commit }, avatar) {
      commit("avatar_change", avatar);
    },
    async getUserDetails({ commit }, token) {
      await commit("getUserDetails", token);
      return "success";
    },
    login({ commit }, user) {
      return axios({
        url: `${process.env.VUE_APP_BASE_URL}/auth/jwt/create/`,
        data: user,
        method: "POST",
        headers: {
          Authorization: null,
        },
      })
        .then((resp) => {
          console.log("router.query", router, router.history.current.query, router.history._startLocation);
          commit("getUserDetails", resp.data.access);
          const startLocation = router.history._startLocation;
          router.push(startLocation === '/login' ? '/chethans-dashboard' : startLocation);
        })
        .catch((e) => {
          Vue.toasted.show(e);
        });
    },
    googleLogin({ commit }, token) {
      axios({
        url: `${process.env.VUE_APP_BASE_URL}/login/google/`,
        data: token,
        method: "POST",
        headers: {
          Authorization: null,
        },
      })
        .then((resp) => {
          commit("getUserDetails", resp.data.access);
          router.push("/home");
        })
        .catch(() => {
          Vue.toasted.show("Whoops! Something went wrong.");
        });
    },
    async register({ commit }, user) {
      await commit("register", user);
    },
    logout({ commit }) {
      commit("logout");
      delete axios.defaults.headers.common["Authorization"];
      localStorage.clear();
    },
    getBrokerData({ commit }, userID) {
      axios({
        method: "get",
        url: `${process.env.VUE_APP_BASE_URL}/broker/${userID}/`,
      }).then((resp) => {
        commit("saveBrokerData", resp.data);
      });
    },
    getAllBrokerUsers({ commit }) {
      axios({
        method: "get",
        url: `${process.env.VUE_APP_BASE_URL}/broker/`,
      }).then((resp) => {
        commit("saveBrokerUsers", resp.data);
      });
    },
    getPortfolioAndAum({ commit }, userID = "all") {
      axios({
        method: "get",
        url: `${process.env.VUE_APP_BASE_URL}/broker-balance/${userID}/`,
      }).then((resp) => {
        commit("savePortfolioAndAum", resp.data);
      });
    },
    getLoginStatusMap({ commit, getters }) {
      let loginStatusMap = {};
      axios({
        url: `${process.env.VUE_APP_BASE_URL}/broker-auth-status/`,
        data: getters.liveBrokers,
        method: "POST",
      }).then((resp) => {
        loginStatusMap = resp.data;
      });
      commit("saveLoginStatusMap", loginStatusMap);
    },
  },
  getters: {
    isLoggedIn: (state) => !!state.token,
    user: (state) => state.user,
    brokers: (state) => state.brokers,
    allBrokers: (state) => state.allBrokers,
    AUM: (state) => state.AUM,
    portfolio: (state) => state.portfolio,
    liveBrokers: (state) => {
      return state.brokers.filter((broker) => broker.broker != "Virtual");
    },
    totalBalance: (state) => {
      const liveBrokers = state.brokers.filter(
        (broker) => broker.broker != "Virtual"
      );
      let balance = 0;
      liveBrokers.forEach((broker) => {
        balance += broker.available_cash;
      });
      return balance;
    },
    liveBrokersWithStatus: (state) => state.liveBrokersWithStatus,
    token: (state) => state.token,
  },
});
