import Vue from "vue";
import App from "./App.vue";
import router from "./router/routes";
import store from "./store/store";
import Toasted from "vue-toasted";

import "vue-awesome/icons";

import Vuesax from "vuesax";
import "vuesax/dist/vuesax.css";
import "material-icons/iconfont/material-icons.css";

Vue.use(Vuesax);

import "prismjs";
import "prismjs/themes/prism.css";

import Icon from "vue-awesome/components/Icon";
Vue.use(Toasted, {
  duration: 2000,
  iconPack: "fontawesome",
  keepOnHover: true,
});

var VueScrollTo = require("vue-scrollto");

Vue.use(VueScrollTo, {
  container: "body",
  duration: 500,
  easing: "ease",
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true,
});

import vheader from "@/components/Header/index";
import vfooter from "@/components/Footer/index";
import sidebar from "@/components/Sidebar/index";

Vue.component("vue-icon", Icon);
Vue.component("v-header", vheader);
Vue.component("v-footer", vfooter);
Vue.component("v-sidebar", sidebar);

import Axios from "axios";

Vue.prototype.$http = Axios;

Vue.config.productionTip = false;
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
