<template>
  <nav class="navbar navbar-expand navbar-theme">
    <!-- <a class="sidebar-toggle d-flex mr-2">
      <i class="hamburger align-self-center"></i>
    </a>-->
    <a class="text-center py-2 px-2" href="/">
      <img height="96" src="https://assets.zyrosite.com/cdn-cgi/image/format=auto,w=350,fit=crop,q=95/Yg2L2GngLBto0lJ6/certiveraa-consultant-logo-png-meP5PNPkOoCE3JR0.png" alt="logo" />
    </a>
    <!-- <div>
      <button v-if="marketOpen == true" class="btn-success ml-3 market-timings"></button>
      <button v-if="marketOpen == false" class="btn-danger ml-3 market-timings"></button>
    </div>-->

    <div class="ml-4">
      <!--  -->
      <!-- <p class="header-subtitle">You have 24 new messages and 5 new notifications.</p> -->
    </div>
    <!-- <form class="form-inline d-none d-sm-inline-block">
      <input class="form-control form-control-lite" type="text" placeholder="Search TradeBeat..." />
    </form>-->

    <div class="navbar-collapse collapse">
      <ul class="navbar-nav ml-auto">
        <!-- <li class="nav-item dropdown active">
          <a
            class="nav-link dropdown-toggle position-relative"
            href="#"
            id="messagesDropdown"
            data-toggle="dropdown"
          >
            <vue-icon name="envelope-open" />
          </a>
          <div
            class="dropdown-menu dropdown-menu-lg dropdown-menu-right py-0"
            aria-labelledby="messagesDropdown"
          >
            <div class="dropdown-menu-header">
              <div class="position-relative">4 New Messages</div>
            </div>
            <div class="list-group">
              <a href="#" class="list-group-item">
                <div class="row no-gutters align-items-center">
                  <div class="col-2">
                    <img src class="avatar img-fluid rounded-circle" alt="Michelle Bilodeau" />
                  </div>
                  <div class="col-10 pl-2">
                    <div class="text-dark">Michelle Bilodeau</div>
                    <div class="text-muted small mt-1">Nam pretium turpis et arcu. Duis arcu tortor.</div>
                    <div class="text-muted small mt-1">5m ago</div>
                  </div>
                </div>
              </a>
              <a href="#" class="list-group-item">
                <div class="row no-gutters align-items-center">
                  <div class="col-2">
                    <img src class="avatar img-fluid rounded-circle" alt="Kathie Burton" />
                  </div>
                  <div class="col-10 pl-2">
                    <div class="text-dark">Kathie Burton</div>
                    <div class="text-muted small mt-1">Pellentesque auctor neque nec urna.</div>
                    <div class="text-muted small mt-1">30m ago</div>
                  </div>
                </div>
              </a>
              <a href="#" class="list-group-item">
                <div class="row no-gutters align-items-center">
                  <div class="col-2">
                    <img src class="avatar img-fluid rounded-circle" alt="Alexander Groves" />
                  </div>
                  <div class="col-10 pl-2">
                    <div class="text-dark">Alexander Groves</div>
                    <div class="text-muted small mt-1">Curabitur ligula sapien euismod vitae.</div>
                    <div class="text-muted small mt-1">2h ago</div>
                  </div>
                </div>
              </a>
              <a href="#" class="list-group-item">
                <div class="row no-gutters align-items-center">
                  <div class="col-2">
                    <img src class="avatar img-fluid rounded-circle" alt="Daisy Seger" />
                  </div>
                  <div class="col-10 pl-2">
                    <div class="text-dark">Daisy Seger</div>
                    <div
                      class="text-muted small mt-1"
                    >Aenean tellus metus, bibendum sed, posuere ac, mattis non.</div>
                    <div class="text-muted small mt-1">5h ago</div>
                  </div>
                </div>
              </a>
            </div>
            <div class="dropdown-menu-footer">
              <a href="#" class="text-muted">Show all messages</a>
            </div>
          </div>
        </li>-->
        <!-- Don't display for chethan -->
        <template v-if="user.id !== 80">
          <li class="nav-item pr-4 mr-2 pt-2">
            <router-link to="/home">Home</router-link>
          </li>
          <li class="nav-item pr-4 mr-2 pt-2">
            <router-link to="/live">Live Trade</router-link>
          </li>
          <!-- <li class="nav-item pr-4 mr-2 pt-2">
          <router-link to="/paper">Paper Trade</router-link>
          </li> -->
          <li class="nav-item pr-4 mr-2 pt-2">
            <router-link to="/journal">Journal</router-link>
          </li>
          <li class="nav-item pr-4 mr-2 pt-2 border-right">
            <router-link to="/library">System Library</router-link>
          </li>
        </template>
        <template v-if="user.id === 80">
          <li class="nav-item pr-4 mr-2 pt-2">
            <router-link to="/chethans-dashboard">Home</router-link>
          </li>
          <li class="nav-item pr-4 mr-2 pt-2">
            <router-link to="/trade-log">Trade Manager</router-link>
          </li>
        </template>
        <li class="nav-item dropdown ml-lg-2">
          <a class="nav-link dropdown-toggle position-relative" href="#" id="alertsDropdown" data-toggle="dropdown"
            @click="markRead">
            <vue-icon name="sync" />
            <span class="indicator" v-if="unreadNotificationCount > 0"></span>
          </a>
          <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right py-0" aria-labelledby="alertsDropdown">
            <div v-if="liveBrokers">
              <div class="list-group" v-for="broker in liveBrokers" :key="broker.title">
                <a href="#" class="list-group-item">
                  <div class="row no-gutters align-items-center">
                    <div class="col-10">
                      <div class="text-dark">{{ broker.broker_username }} - {{ broker.username }}</div>
                    </div>
                    <div class="col-2">
                      <button class="btn " :class="{
                        'btn-success': loginStatusMap[broker.username] === true,
                        'btn-danger': loginStatusMap[broker.username] !== true,
                      }" @click="loginRedirect(broker.api_key)">
                        Login
                      </button>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div class="list-group" v-else>
              <a href="#" class="list-group-item">
                <div class="row no-gutters align-items-center">
                  <div class="col-7">
                    <div class="text-dark">No brokers</div>
                  </div>
                  <div class="col-5">
                    <router-link to="/settings/broker" class="btn btn-primary">Add Broker</router-link>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </li>
        <li class="nav-item dropdown ml-lg-2">
          <a class="nav-link dropdown-toggle position-relative" href="#" id="alertsDropdown" data-toggle="dropdown"
            @click="markRead">
            <vue-icon name="bell" />
            <span class="indicator" v-if="unreadNotificationCount > 0"></span>
          </a>
          <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right py-0" aria-labelledby="alertsDropdown">
            <div class="dropdown-menu-header">Latest Notifications</div>
            <div class="list-group" v-for="notification in notifications" :key="notification.title">
              <a href="#" class="list-group-item">
                <div class="row no-gutters align-items-center">
                  <div class="col-2">
                    <!-- <i class="ml-1 text-danger fas fa-fw fa-bell"></i> -->
                    <vue-icon name="building" />
                  </div>
                  <div class="col-10">
                    <div class="text-dark">{{ notification.title }}</div>
                    <div class="text-muted small mt-1">
                      {{ notification.description.slice(0, 95) }}
                    </div>
                    <div class="text-muted small mt-1">
                      {{ toDate(notification.created_on) }}
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div class="dropdown-menu-footer">
              <router-link to="/notifications" class="text-muted">Show all notifications</router-link>
            </div>
          </div>
        </li>
        <li class="nav-item dropdown ml-lg-2">
          <a class="nav-link dropdown-toggle position-relative" href="#" id="userDropdown" data-toggle="dropdown">
            <vue-icon name="cog" />
          </a>
          <div class="dropdown-menu dropdown-menu-right" aria-labelledby="userDropdown">
            <router-link class="dropdown-item" to="/settings/user">
              <i class="align-middle mr-1 fas fa-fw fa-cogs"></i> User
            </router-link>
            <div class="dropdown-divider"></div>
            <router-link class="dropdown-item" to="/settings/broker">
              <i class="align-middle mr-1 fas fa-fw fa-cogs"></i> Broker
            </router-link>
            <div class="dropdown-divider"></div>
            <router-link class="dropdown-item" to="/billing">
              <i class="align-middle mr-1 fas fa-fw fa-cogs"></i> Billing
            </router-link>
            <div class="dropdown-divider"></div>
            <router-link class="dropdown-item" to="/faq">
              <i class="align-middle mr-1 fas fa-fw fa-cogs"></i>Contact Us
            </router-link>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" href @click="logout">
              <i class="align-middle mr-1 fas fa-fw fa-arrow-alt-circle-right"></i>
              Sign out
            </a>
          </div>
        </li>
      </ul>
    </div>
  </nav>
</template>
<script>
// import $ from "jquery";

export default {
  name: "vheader",
  data() {
    return {
      notifications: [],
      unreadNotificationCount: 0,
      marketOpen: true,
      loginStatusMap: {},
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    liveBrokers() {
      return this.$store.getters.liveBrokers;
    },
    liveBrokersWithStatus() {
      return this.$store.getters.liveBrokersWithStatus;
    },
  },
  mounted() {
    this.isMarketOpen();
    // this.getNotifications();
    this.getBrokerAuthStatus();
    // get logged in status every 2 minutes
    this.interval = setInterval(() => this.getBrokerAuthStatus(), 120000);
  },
  methods: {
    loginRedirect(apiKey) {
      window.open(`https://kite.zerodha.com/connect/login?v=3&api_key=${apiKey}`, '_blank', 'noreferrer')
    },
    logout() {
      this.$store.dispatch("logout").then(() => this.$router.push("/login"));
    },
    getNotifications() {
      this.$http({
        method: "get",
        url: `${process.env.VUE_APP_BASE_URL}/notifications/${this.user.id}/`,
      }).then((resp) => {
        this.notifications = resp.data.reverse();
        let counter = 0;
        resp.data.forEach((notif) => {
          if (notif.is_read == false) {
            counter += 1;
          }
        });
        this.unreadNotificationCount = counter;
      });
    },
    markRead() {
      this.notifications.forEach((notif) => {
        this.$http({
          method: "put",
          data: { is_read: true, is_global: notif.is_global },
          url: `${process.env.VUE_APP_BASE_URL}/notifications/${notif.id}/`,
        });
      });
      this.unreadNotificationCount = 0;
    },
    toDate(ts) {
      const date = new Date(ts);
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      return `${months[date.getMonth()]
        } ${date.getDate()} ${date.getFullYear()}`;
    },
    isMarketOpen() {
      var startTime = "09:30:00";
      var endTime = "15:30:00";

      let currentDate = new Date();

      // Need to check for saturday and sunday
      if (currentDate.getDay() == 0 || currentDate.getDay() == 6) {
        this.marketOpen = false;
        return;
      }
      let startDate = new Date(currentDate.getTime());
      startDate.setHours(startTime.split(":")[0]);
      startDate.setMinutes(startTime.split(":")[1]);
      startDate.setSeconds(startTime.split(":")[2]);

      let endDate = new Date(currentDate.getTime());
      endDate.setHours(endTime.split(":")[0]);
      endDate.setMinutes(endTime.split(":")[1]);
      endDate.setSeconds(endTime.split(":")[2]);

      this.marketOpen = startDate < currentDate && endDate > currentDate;
    },
    getBrokerAuthStatus() {
      this.$store.dispatch("getBrokerAuthStatus");
    },
  },
};
</script>

<style lang="scss">
a {
  color: #656565;
}

.market-timings {
  border-radius: 50%;
  margin-top: -10px;
  border: 1px;
  height: 13px;
  width: 13px;
}
</style>
