<template>
  <nav id="sidebar" class="sidebar">
    <a class="sidebar-brand text-center py-4" href="/">
      <img height="38" src="@/assets/images/logo.png" alt="logo" />
    </a>
    <div class="sidebar-content">
      <div class="sidebar-user">
        <img :src="user.avatar_url" class="img-fluid rounded-circle mb-2" alt />
        <div class="font-weight-bold">{{ user.name }}</div>
        <small>{{ user.email }}</small>
      </div>

      <ul class="sidebar-nav">
        <li class="sidebar-item">
          <router-link class="sidebar-link" to="/home">
            <vue-icon name="home" />
            <span class="align-middle">Home</span>
          </router-link>
        </li>
        <li class="sidebar-header">Trade Room</li>
        <li class="sidebar-item">
          <router-link class="sidebar-link" to="/live">
            <vue-icon name="desktop" />
            <span class="align-middle">Live Trading</span>
          </router-link>
        </li>
        <li class="sidebar-item">
          <router-link class="sidebar-link" to="/paper">
            <vue-icon name="paper-plane" />
            <span class="align-middle">Paper Trading</span>
          </router-link>
        </li>
        <li class="sidebar-item">
          <router-link class="sidebar-link" to="/library">
            <vue-icon name="shopping-cart" />
            <span class="align-middle">System Library</span>
          </router-link>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
export default {
  name: "sidebar",
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
};
</script>
